<template>
	<div class="d-flex flex-wrap">
		<v-switch
			v-for="permissionSwitch in permissionSwitches"
			:key="permissionSwitch[0]"
			:label="permissionSwitch[1]"
			:input-value="getPermissionSwitchValue(permissionSwitch[0])"
			:disabled="readonly"
			class="px-4 mt-0"
			style="width: 30rem"
			hide-details
			@change="changePermissionSwitchValue(permissionSwitch[0])"
		/>
	</div>
</template>

<script>
import {
	permissionLabels,
	SEE_DATA_SUBJECT_MANAGEMENT_AREA,
	DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS,
	CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES,
	SEE_DATA_SUBJECT_PREFERENCE_HISTORY,
	SEE_DATA_SUBJECT_SUBMISSION_HISTORY,
	SEE_MANAGE_CSPORTAL_USERS_AREA,
	CREATE_UPDATE_CSPORTAL_USERS,
	SEE_DATA_SUBJECT_PREFERENCE_HISTORY_CHILD_SWITCHES
} from '../permissions/cs-portal-permissions.js'

export default {
	props: {
		permissions: {
			type: Array,
			default: () => []
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		permissionSwitches () {
			let switches = Object.entries(permissionLabels).map(entry => [Number(entry[0]), entry[1]])
			// if Data Subjects - Full Permissions = on - hide the children
			if (this.permissions.includes(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)) {
				return switches.filter(permissionsSwitch => !CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.includes(permissionsSwitch[0]))
			}
			// hide can create & edit users if can view users is off
			if (!this.permissions.includes(SEE_MANAGE_CSPORTAL_USERS_AREA)) {
				switches = switches.filter(
					permissionsSwitch => permissionsSwitch[0] !== CREATE_UPDATE_CSPORTAL_USERS
				)
			}
			// hide Data Subjects - Full Permissions & this children
			if (!this.permissions.includes(SEE_DATA_SUBJECT_MANAGEMENT_AREA)) {
				return switches.filter(
					permissionsSwitch => !CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.includes(permissionsSwitch[0]) && permissionsSwitch[0] !== DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS
				)
			}
			if (!this.permissions.includes(SEE_DATA_SUBJECT_PREFERENCE_HISTORY) && !this.permissions.includes(SEE_DATA_SUBJECT_SUBMISSION_HISTORY)) {
				return switches.filter(
					permissionsSwitch => !SEE_DATA_SUBJECT_PREFERENCE_HISTORY_CHILD_SWITCHES.includes(permissionsSwitch[0])
				)
			} else {
				return switches
			}
		}
	},
	methods: {
		getPermissionSwitchValue (permissionId) {
			return this.permissions.includes(permissionId)
		},
		changePermissionSwitchValue (permissionId) {
			if (this.readonly) return
			const hasPermission = this.getPermissionSwitchValue(permissionId)
			let permissions
			// toggle off
			if (hasPermission) {
				permissions = this.permissions.filter(id => id !== permissionId)
				if (permissionId === SEE_DATA_SUBJECT_MANAGEMENT_AREA) {
					permissions = permissions.filter(id => !CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.includes(id) && id !== DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)
				}
				// remove can create cs portal users when toggle off can view cs protal users
				if (permissionId === SEE_MANAGE_CSPORTAL_USERS_AREA) {
					permissions = permissions.filter(id => id !== CREATE_UPDATE_CSPORTAL_USERS)
				}
				// toggle on
			} else {
				permissions = [...this.permissions, permissionId]
				// toggles the children off if "full permissions is on"
				if (permissionId === DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS) {
					permissions = permissions.filter(id => !CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.includes(id))
				}

				const allChildrenPresent = CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.every(child => {
					return permissions.includes(child)
				})
				// if all children are checked manually - check "full permissions" and remove the children
				if (allChildrenPresent) {
					permissions.push(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)
					permissions = permissions.filter(id => !CS_PORTAL_DATA_SUBJECTS_CHILD_SWITCHES.includes(id))
				}
			}
			this.$emit('update:permissions', permissions)
		}
	}
}
</script>
