import { configApi } from '../http-client.js'

export const getProductRoles = (productId = '') => {
	return configApi.get(`/api/ProductRoles?productId=${productId}`).then(({ data: { productRoles } }) => {
		return {
			data: productRoles
		}
	})
}

export const getProductRole = roleId => {
	return configApi.get(`/api/ProductRoles/${roleId}`)
}

export const deleteProductRole = roleId => {
	return configApi.delete(`/api/ProductRoles/${roleId}`)
}

export const putProductRole = role => {
	return configApi.put('/api/ProductRoles', {
		productRoleId: role.roleId,
		roleName: role.name,
		assignedPermissions: role.permissions
	})
}

export const upsertProductRole = (productId, role) => {
	return configApi.post('/api/ProductRoles/Upsert', {
		productId: productId,
		productRoleId: role.roleId,
		roleName: role.name,
		assignedPermissions: role.permissions
	})
}

export const getAdminPortalPermissions = () => {
	const permissions = configApi.get('/api/ProductRoles/AdminPortalPermissions').then(({ data: { pages } }) => {
		return {
			data: pages
		}
	})
	return permissions
}
