<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
	>
		<ProductRolesLayout	:product-id="productIdToView">
			<template #content>
				<ProductRolesRoleForm
					:role.sync="role"
					:product-id="productIdToView"
					is-edit
					:view-only="readOnly"
				>
					<template #title>
						{{ readOnly || role.isSystem ? 'View' : 'Edit' }} {{ role.isSystem ? 'System' : '' }} User Role
					</template>
					<template #bottom>
						<div class="text-subtitle-1 font-weight-medium">
							Associated Users
						</div>
						<v-simple-table
							dense
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											style="width: 15%;"
										>
											Forename
										</th>
										<th
											scope="col"
											style="width: 15%;"
										>
											Surname
										</th>
										<th
											scope="col"
											style="width: 70%;"
										>
											Email Address
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(user, index) in role.users"
										:key="index"
									>
										<td>
											{{ user.forename }}
										</td>
										<td>
											{{ user.surname }}
										</td>
										<td>
											{{ user.email }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</template>
				</ProductRolesRoleForm>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="!role.isSystem && !readOnly"
						@click="handleSubmit(saveChanges)"
					>
						Save
					</PrimaryActionButton>
				</div>
			</template>
		</ProductRolesLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import { CS_PORTAL_PRODUCT_ROLES, ADMIN_PORTAL_PRODUCT_ROLES } from '../../router/route-names.js'
import ProductRolesRoleForm from './product-roles-role-form.vue'
import SecondaryActionButton from '../secondary-action-button.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import ProductRolesLayout from './product-roles-layout.vue'
import { getProductRole, upsertProductRole } from '../../utils/api/product-roles.js'
import { showSnackbar } from '../../state/snackbar.js'
import ValidationForm from '../validation-form.vue'
import {
	ADMINISTRATION_MODULE_FULL_PERMISSIONS,
	CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS,
	CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES,
	CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES
} from '../../permissions/admin-portal-permissions.js'
import { CREATE_UPDATE_CSPORTAL_USERS } from '../../permissions/cs-portal-permissions.js'

export default {
	components: { ValidationForm, ProductRolesLayout, PrimaryActionButton, SecondaryActionButton, ProductRolesRoleForm },
	props: {
		roleId: Number,
		productIdToView: Number,
		loggedInProductId: Number
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			role: {
				isSystem: false,
				users: [],
				productRoleId: null,
				name: '',
				permissions: []
			}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'hasPermission']),
		readOnly () {
			if (this.loggedInProductId === 1) {
				if (this.productIdToView === 1) {
					return !this.productAreaPermission(ADMINISTRATION_MODULE_FULL_PERMISSIONS) &&
						!this.productAreaPermission(CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES)
				} else {
					return !this.productAreaPermission(CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS) &&
						!this.productAreaPermission(CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES)
				}
			}	else {
				return !this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
			}
		}
	},
	async created () {
		const { data: role } = await getProductRole(this.roleId)
		this.role = role
	},
	methods: {
		navigateToOverview () {
			this.$router.push({
				name: this.productIdToView === 1 ? ADMIN_PORTAL_PRODUCT_ROLES : CS_PORTAL_PRODUCT_ROLES
			})
		},
		async saveChanges () {
			await upsertProductRole(this.productIdToView, this.role)
			this.showSnackbar('This user role has been updated')
			this.navigateToOverview()
		}
	}
}
</script>
