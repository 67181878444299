var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md"},[_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"cassie-vertical-md"},[_c('DataTable',{attrs:{"items":_vm.formattedPermissions,"headers":_vm.tableHeaders,"items-per-page":-1,"hide-default-footer":true,"item-class":_vm.rowClass,"fixed-header":"","height":"60vh"},scopedSlots:_vm._u([{key:"item.page",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ fontWeight: item.level === 1 || item.isSpecial ? 'bold' : 'normal', marginLeft: item.level * 20 + 'px', textWrap: 'nowrap' })},[_vm._v(" "+_vm._s(item.page)+" ")])]}},{key:"item.fullPermission",fn:function(ref){
var item = ref.item;
return [(item.fullPermission)?_c('v-switch',{attrs:{"input-value":_vm.selectedPermissions.includes(item.fullPermission),"disabled":_vm.readonly},on:{"change":function($event){return _vm.updateSelectedPermissions(item.fullPermission, item.parentId, item.pageId, 'fullPermission', item.isSpecial)}}}):_vm._e()]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [(item.read)?_c('v-switch',{attrs:{"input-value":_vm.selectedPermissions.includes(item.read),"disabled":_vm.readonly},on:{"change":function($event){return _vm.updateSelectedPermissions(item.read, item.parentId, item.pageId, 'read', item.isSpecial)}}}):_vm._e()]}},{key:"item.createAndEdit",fn:function(ref){
var item = ref.item;
return [(item.createAndEdit)?_c('v-switch',{attrs:{"input-value":_vm.selectedPermissions.includes(item.createAndEdit),"disabled":_vm.readonly},on:{"change":function($event){return _vm.updateSelectedPermissions(item.createAndEdit, item.parentId, item.pageId, 'createAndEdit', item.isSpecial)}}}):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.delete)?_c('v-switch',{attrs:{"input-value":_vm.selectedPermissions.includes(item.delete),"disabled":_vm.readonly},on:{"change":function($event){return _vm.updateSelectedPermissions(item.delete, item.parentId, item.pageId, 'delete', item.isSpecial)}}}):_vm._e()]}}])})],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }