<template>
	<SectionCard>
		<template #title>
			<slot name="title" />
		</template>
		<template #body>
			<Message v-if="role.isSystem">
				You cannot change the name or edit permissions for system user roles
			</Message>
			<TextField
				:value="role.name"
				:disabled="role.isSystem || viewOnly"
				rules="required"
				:label=" (productId === 1 ? 'User' : 'Product') + ' Role Name *'"
				width="300px"
				@input="updateRole('name', $event)"
			/>
			<PermissionsCard v-if="isRequiredDataPopulated">
				<AdminPortalPermissions
					v-if="productId === 1"
					:permissions="role.permissions"
					:readonly="role.isSystem || viewOnly"
					@update:permissions="updateRole('permissions', $event)"
				/>
				<CsPortalPermissions
					v-if="productId === 2"
					:permissions="role.permissions"
					:readonly="role.isSystem || viewOnly"
					@update:permissions="updateRole('permissions', $event)"
				/>
			</PermissionsCard>
			<slot name="bottom" />
		</template>
	</SectionCard>
</template>

<script>
import PermissionsCard from '../../../cs-portal/src/components/views/permissions-card.vue'
import TextField from '../text-field.vue'
import CsPortalPermissions from '../cs-portal-permissions.vue'
import AdminPortalPermissions from '../admin-portal-permissions.vue'
import SectionCard from '../section-card.vue'
import Message from '../message.vue'
export default {
	components: { SectionCard, CsPortalPermissions, TextField, PermissionsCard, AdminPortalPermissions, Message },
	props: {
		role: {
			type: Object,
			default: () => ({})
		},
		productId: Number,
		isEdit: {
			type: Boolean,
			default: false
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isRequiredDataPopulated () {
			if (this.isEdit) {
				return this.role.name && !!this.role.name
			} else return true
		}
	},
	methods: {
		updateRole (property, value) {
			this.$emit('update:role', {
				...this.role,
				[property]: value
			})
		}
	}
}
</script>
