<template>
	<ViewLayout>
		<template #header-title>
			{{ productId === 1 ? 'Admin Portal User Roles' : 'CS Portal User Roles' }}
		</template>
		<template #header-caption>
			Add, edit and manage {{ productId === 1 ? 'Admin Portal' : 'CS Portal' }} User's Roles
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../layouts/view-layout.vue'
export default {
	components: { ViewLayout },
	props: {
		productId: Number
	}
}
</script>
